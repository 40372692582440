<template>
  <a-table rowKey="id" :data-source="list" :pagination="pagination" :loading="listLoading" @change="handleTableChange">
    <a-table-column key="name" title="机构名称" align="center" data-index="name" width="120px" />
    <a-table-column key="level" title="级别" align="center" data-index="level" width="80px">
      <template slot-scope="level">
        <span>
          {{ level | FieldLevelText() }}
        </span>
      </template>
    </a-table-column>
    <a-table-column key="cellphone" title="联系电话" align="center" data-index="cellphone" width="120px" />
    <a-table-column key="createTimestamp" title="创建时间" align="center" data-index="createTimestamp" width="120px" />
    <a-table-column key="studentCount" title="学生总数" align="center" data-index="studentCount" width="120px" />
    <a-table-column key="userCount" title="员工总数" align="center" data-index="userCount" width="120px" />
    <a-table-column key="manage" title="机构管理" align="center" width="200px">
      <template slot-scope="record">
        <a-space>
          <a-button type="link" size="small" @click="onAdd(record)" v-if="$auth('mechanism_add') && !userMechanismLevel"
            >新增下级</a-button
          >
          <a-button type="link" size="small" @click="onView(record)" v-if="!userMechanismLevel">查看下级</a-button>
          <a-button type="link" size="small" @click="onUserList(record)">人员列表</a-button>
        </a-space>
      </template>
    </a-table-column>
    <a-table-column key="actions" title="操作" align="center" width="140px" v-if="getUserMechanismIsPartyA">
      <template slot-scope="record">
        <a-space>
          <a-button v-if="$auth('mechanism_update')" type="link" size="small" @click="onEdit(record)"
            ><a-icon type="edit" />编辑</a-button
          >
          <a-button
            v-if="$auth('mechanism_delete')"
            type="link"
            size="small"
            @click="handleRemove(record)"
            class="error-color"
            ><a-icon type="delete" />删除</a-button
          >
        </a-space>
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
import * as mechanismApi from '@/api/mechanism'
import { mapGetters } from 'vuex'
import store from '@/store'
export default {
  data() {
    return {
      listLoading: false,
      list: [],
      query: {
        name: '',
        parentId: ''
      },
      pagination: {}
    }
  },
  computed: {
    ...mapGetters({
      userMechanismLevel: 'getUserMechanismLevel',
      getUserMechanismIsPartyA: 'getUserMechanismIsPartyA' // 用户机构id是否“北大医疗脑健康” 、“超级机构”
    })
  },
  methods: {
    initData(query = {}) {
      this.query = query
      this.pagination = this.initPagination()
      return this.getData()
    },
    getData() {
      this.listLoading = true
      const {
        pagination: { current: pageIndex, pageSize },
        query
      } = this
      return mechanismApi
        .list({ ...query, pageIndex, pageSize })
        .then(res => {
          const { list, totalItems: total } = res.data
          this.list = list
          this.pagination.total = total
          store.dispatch('getInfo')
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 10,
        showTotal: total => {
          const element = this.$createElement
          const {
            pagination: { pageSize }
          } = this
          return element('div', {}, [
            element('span', '共 '),
            element('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 10)),
            element('span', ' 页'),
            element('span', ' / '),
            element('span', { class: 'primary-color' }, total),
            element('span', ' 条数据')
          ])
        },
        showSizeChanger: true,
        total: 0,
        pageSizeOptions: ['10', '20', '50', '100']
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize

      getData()
    },
    onAdd(record) {
      this.$emit('edit', { type: 'ADD_CHILDRED', parentId: record.id })
    },
    onEdit(record) {
      mechanismApi.detail(record.id).then(res => {
        const {
          name,
          id,
          cellphone,
          status,
          userRegistrationLimit,
          studentRegistrationLimit,
          mechanismRegistrationLimit
        } = res.data
        this.$emit('edit', {
          type: 'EDIT',
          name,
          id,
          cellphone,
          status,
          userRegistrationLimit,
          studentRegistrationLimit,
          mechanismRegistrationLimit
        })
      })
    },
    onView(record) {
      const { id } = record
      this.$router.push({
        name: 'PermissionOrganizationChildlist',
        params: { id }
      })
    },
    onUserList(record) {
      this.$router.push({ path: `/permission/organization/userlist/${record.id}` })
    },
    handleRemove(record) {
      const confirm = this.$confirm({
        title: '删除',
        content: '您确定要删除吗',
        onOk: () => {
          return mechanismApi
            .remove(record.id)
            .then(res => {
              this.$notification['success']({ message: '操作成功' })
              this.getData()
            })
            .catch(err => {
              confirm.destroy()
              this.$info({
                title: '不可删除',
                content: err.msg
              })
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-btn {
  font-size: 12px;
}
</style>
