import request from '@/utils/request'
import user from '@/store/modules/user'

const commonApi = {
  dicts: '/dicts/type',
  fieldsOptions: '/fields/all',
  mechanismOptions: '/mechanism/all',
  resources: '/resources',
  addPlanSkill: studentId => `/homeschool-plans/${studentId}/self-plan-skill`,
  //  获取机构上限数量
  maximumNumber: mechanismId => `/mechanism/${mechanismId}/limit/quantity`
}
// 获取机构上限数量
export function commonGetMechanism() {
  const { mechanismId } = user.state.info
  return request({
    url: commonApi.maximumNumber(mechanismId),
    method: 'get'
  })
}

// 该学员是否可以添加自选技能包
export function isAddPlanSkill(parameter) {
  const { studentId } = parameter
  return request({
    url: commonApi.addPlanSkill(studentId),
    method: 'get'
  })
}

// 上传文件信息换取id
export function resources(parameter) {
  return request({
    url: commonApi.resources,
    method: 'post',
    data: parameter
  })
}

export function dicts(parameter) {
  return request({
    url: commonApi.dicts,
    method: 'get',
    params: parameter
  })
}

export function fieldsOptions(parameter) {
  return request({
    url: commonApi.fieldsOptions,
    method: 'get',
    params: parameter
  })
}

export function mechanismOptions() {
  return request({
    url: commonApi.mechanismOptions,
    method: 'get'
  })
}
