<template>
  <div>
    <a-card :bordered="false" style="margin-bottom: 24px">
      <a-row :gutter="[24, 0]">
        <a-form-model class="ant-advanced-search-form">
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item label="机构名称：">
              <a-input-search v-model="query.name" placeholder="请输入" style="width: 100%" @search="initData" />
            </a-form-model-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item label="创建时间：">
              <a-range-picker style="flex: 1" valueFormat="YYYY-MM-DD" allowClear @change="onDatechange" />
            </a-form-model-item>
          </a-col>
        </a-form-model>
      </a-row>
    </a-card>
    <a-card :body-style="{ padding: 0 }">
      <div slot="title" style="display: flex; justify-content: space-between">
        <span>机构列表</span>
        <div class="statistics">
          <span
            >子机构创建上限：{{
              maximumNumber.mechanismRegistrationLimit === unlimitedNumber
                ? '无限制'
                : maximumNumber.mechanismRegistrationLimit
            }}</span
          >
          <span>已有子机构数量：{{ maximumNumber.mechanismRegistrationCount }}</span>
        </div>
      </div>
      <div slot="extra">
        <a-button
          v-if="$auth('mechanism_add') && !userMechanismLevel"
          type="primary"
          shape="round"
          @click="onAdd"
          :disabled="maximumNumber.mechanismRegistrationCount >= maximumNumber.mechanismRegistrationLimit"
          ><a-icon type="plus" />新增机构</a-button
        >
      </div>
      <main-table ref="table" @edit="onEdit"></main-table>
    </a-card>
    <Model ref="model" :dataSource="modalData" />
  </div>
</template>

<script>
import MainTable from './components/Table'
import Model from './components/Model'
import { cloneDeep } from 'lodash'
import * as commonApi from '@/api/common'
import { mapState, mapGetters } from 'vuex'
const modalData = {
  type: '',
  title: '',
  name: '',
  parentId: '',
  status: '',
  cellphone: '',
  studentRegistrationLimit: '1',
  userRegistrationLimit: '1',
  mechanismRegistrationLimit: '0'
}
export default {
  name: 'PermissionOrganizationList',
  components: {
    MainTable,
    Model
  },
  data() {
    return {
      query: { name: '', startDate: '', endDate: '' },
      modalData: {},
      // 学员管理、机构管理、员工管理上限
      maximumNumber: {}
    }
  },
  computed: {
    ...mapState({
      // 无限制的数量
      unlimitedNumber: state => state.user.unlimitedNumber
    }),
    ...mapGetters({
      userMechanismLevel: 'getUserMechanismLevel'
    })
  },
  activated() {
    if (this.ready) {
      this.initData()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
    this.initData()
  },
  methods: {
    // 日期筛选
    onDatechange(date) {
      this.query.startDate = date[0]
      this.query.endDate = date[1]
      this.initData()
    },
    initData() {
      this.$refs.table.initData(this.query)
      // 学员管理、机构管理、员工管理上限
      commonApi.commonGetMechanism().then(res => {
        const { code, data } = res
        if (code === 0) {
          this.maximumNumber = data
        }
      })
    },
    onAdd() {
      const {
        $refs: { model },
        $nextTick,
        initData
      } = this
      this.modalData = cloneDeep(modalData)
      $nextTick(() =>
        model.showModal(e => {
          initData()
        })
      )
    },
    onEdit(record) {
      if (record) {
        this.modalData = Object.assign(cloneDeep(modalData), record)
      }
      const {
        $refs: { model },
        $nextTick,
        initData
      } = this
      $nextTick(() =>
        model.showModal(e => {
          initData()
        })
      )
    }
  }
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
.ant-advanced-search-form /deep/ .ant-input-group {
  background: #fff;
}

.statistics {
  span {
    margin-right: 24px;
    color: #595959;
    font-size: 14px;
  }
}
</style>
